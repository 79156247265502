<template>
    <div class="brand-container">
        <div class="wrap">
            <header>
                <div class="weather"></div>
                <h2>整体概况</h2>
                <div class="showTime">
                </div>
            </header>

            <section class="mainbox">
                <div class="item left">
                    <div class="panel">
                        <h2>专业学生数TOP5</h2>
                        <major-top />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel-left2">
                        <h2>课程概况</h2>
                        <course-info />
                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item center">
                    <div class="resume">
                        <div class="resume-hd">
                            <ul>
                                <li>
                                    <countTo :startVal='startVal' :endVal='registeredNum' :duration='6000' separator=""></countTo>
                                </li>
                                <li>
                                    <countTo :startVal='startVal' :endVal='graduationNum' :duration='6000' separator=""></countTo>
                                </li>
                            </ul>
                        </div>
                        <div class="resume-bd">
                            <ul>
                                <li>在籍学生数</li>
                                <li>已毕业学生数</li>
                            </ul>
                        </div>
                    </div>

                    <div class="panel-live">

                        <a-row :gutter="36">
                            <a-col :span="8">
                                <a-card hoverable class="card left-card">
                                    <a-card-meta title="" >
                                        <template slot="description">
                                            <h2>专科热门专业TOP5</h2><br>
                                            <div v-for="(item, index) in collegeHotMajorList" :key="index">
                                                <p class="major-class">{{item.majorName}}</p><br>
                                            </div>
                                        </template>
                                    </a-card-meta>
                                </a-card>
                            </a-col>

                            <a-col :span="8">
                            <a-card hoverable class="card center-card">
                                <a-card-meta title="" >
                                    <template slot="description">
                                        <h2> 本科热门专业TOP5</h2><br>
                                        <div v-for="(item, index) in universityHotMajorList" :key="index">
                                            <p class="major-class">{{item.majorName}}</p><br>
                                        </div>
                                    </template>
                                </a-card-meta>
                            </a-card>
                            </a-col>

                            <a-col :span="8">
                            <a-card hoverable class="card rigth-card">
                                <a-card-meta title="" >
                                    <template slot="description">
                                        <h2>热门选课TOP5</h2><br>
                                        <div v-for="(item, index) in hotChooseList" :key="index">
                                            <p class="major-class">{{item.courseName}}</p><br>
                                        </div>
                                    </template>
                                </a-card-meta>
                            </a-card>
                            </a-col>
                        </a-row>

                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item right">
                    <div class="panel">
                        <h2></h2>
                        <div class="resume-right">
                            <div class="resume-hd">
                                <ul>
                                    <li>
                                        <countTo :startVal='startVal' :endVal='newbornNum' :duration='6000' separator=""></countTo>
                                    </li>
                                </ul>
                            </div>
                            <div class="resume-bd">
                                <ul>
                                    <li>新生数</li>
                                </ul>
                            </div>

                            <div class="resume-hd">
                                <ul>
                                    <li>
                                        <countTo :startVal='startVal' :endVal='majorNum' :duration='6000' separator=""></countTo>
                                    </li>
                                </ul>
                            </div>
                            <div class="resume-bd">
                                <ul>
                                    <li>专业数</li>
                                </ul>
                            </div>
                            <div class="panel-footer"></div>
                        </div>
                    </div>

                </div>
            </section>

        </div>

    </div>
</template>
<script>
    import '@/assets/js/flexible'
    import countTo from 'vue-count-to'
    import MajorTop from "./c/MajorTop.vue";
    import CourseInfo from "./c/CourseInfo.vue";
    import api from '@/api'

    export default {
        components: {
            CourseInfo,
            MajorTop,
            countTo
        },
        name: 'whole-info',
        data() {
            return {
                startVal: 0,
                registeredNum: 0,
                graduationNum: 0,
                newbornNum: 0,
                majorNum: 0,
                collegeHotMajorList: [],
                universityHotMajorList: [],
                hotChooseList: []
            }
        },
        created() {
            this.initOrganizationByDomain();
        },
        mounted() {
            this.getWholeCount();
            this.getHotMajor();
        },
        methods: {
            async getWholeCount() {
                let res = await api.bigdata.getWholeCount({organizationId: localStorage.getItem('organizationId')})
                if (res.code === 200) {
                    this.registeredNum = res.data.registeredNum;
                    this.graduationNum = res.data.graduationNum;
                    this.newbornNum = res.data.newbornNum;
                    this.majorNum = res.data.majorNum;
                }
            },
            async getHotMajor() {
                let res = await api.bigdata.getHotMajor({organizationId: localStorage.getItem('organizationId')})
                if (res.code === 200) {
                    this.collegeHotMajorList = res.data.collegeHotMajorList;
                    this.universityHotMajorList = res.data.universityHotMajorList;
                    this.hotChooseList = res.data.hotChooseList;
                }
            },
            async initOrganizationByDomain() {
                const organizationId = localStorage.getItem('organizationId')
                if (!organizationId) {
                    const domain =  window.location.origin || 100000
                    let res = await api.protal.getOrganizationByDomain({ domain })
                    if (res.code === 200 && res.data) {
                        localStorage.setItem('organizationId', res.data.organizationId)
                        location.reload();
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/bigdata-screen/today-dynamic.scss";
    .card {
        width: 3rem;
        height: 6rem;
        background: rgba(0, 153, 145, 0.2);
    }
    .left-card {
        margin-left: -2.75rem;
        margin-top: 1rem;
        color: white;
    }
    .center-card {
        margin-top: 1rem;
        position: relative;
        left: 50%;
        margin-left: -1.5rem;
    }
    .rigth-card {
        margin-right: -2.75rem;
        float: right;
        margin-top: 1rem;
    }
    .ant-card-meta-detail {
        overflow: hidden;
        color: rgb(244 240 240 / 85%);
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .major-class {
        color: white;
        font-size: 16px;
        margin-top: 0rem;
    }
</style>