<template>
    <div class="business-container">
        <div>
            <div class="chart" id="chart_left2"></div>
            <span class="text">已开设直播课的专业覆盖率</span>
            <div class="right-chart" id="chart_left3"></div>
            <span class="right-text">已开设自建课的专业覆盖率</span>
        </div>
    </div>
</template>

<script>
import api from '@/api'

export default {
    name: 'course-info',
    data() {
        return {
            majorNum: 0,
            liveMajorNum: 0,
            selfMajorNum: 0,
        }
    },
    mounted() {
        this.getEchartLeft2();
    },
    methods: {
        async getEchartLeft2() {
            // 实例化对象
            let myChart = echarts.init(document.getElementById('chart_left2'));

            let res = await api.bigdata.getWholeMajorCourse({organizationId: localStorage.getItem('organizationId'),isCurrent: "Y"})
            if (res.code === 200) {
                this.majorNum = res.data.majorNum;
                this.liveMajorNum = res.data.liveMajorNum;
                this.selfMajorNum = res.data.selfMajorNum;
            }
            this.option = {
                title: {
                    text: Number(this.liveMajorNum/this.majorNum * 100).toFixed(2) + '%',  //图形标题，配置在中间对应效果图的80%
                    left: "center",
                    top: "center",
                    textStyle: {
                        color: "rgb(50,197,233)",
                        fontSize: 16,
                        align: "center"
                    }
                },
                series: [{
                    name: '环形饼图',
                    type: 'pie',  //环形图的type和饼图相同
                    radius: ['68%', '80%'], //饼图的半径，第一个为内半径，第二个为外半径
                    hoverAnimation: false,
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: { //鼠标移入时效果
                        label: {
                            show: true
                        }
                    },
                    labelLine: { //提示文字
                        show: false
                    },
                    data: [{
                        value: this.liveMajorNum,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#6984fe'
                                },{
                                    offset: 1,
                                    color: '#24d1fd'
                                }])
                            }
                        }
                    },{
                        value: this.majorNum - this.liveMajorNum,
                        itemStyle: {
                            normal: {
                                color: '#eee'
                            }
                        }
                    }]
                }]
            }

            myChart.setOption(this.option, true);

            window.addEventListener('resize', () => {
                myChart.resize();
            });
            this.getEchartLeft3();
        },
        getEchartLeft3() {
            // 实例化对象
            let myChart = echarts.init(document.getElementById('chart_left3'));
            this.option = {
                title: {
                    text: Number(this.selfMajorNum/this.majorNum * 100).toFixed(2) + '%',  //图形标题，配置在中间对应效果图的80%
                    left: "center",
                    top: "center",
                    textStyle: {
                        color: "rgb(50,197,233)",
                        fontSize: 16,
                        align: "center"
                    }
                },
                series: [{
                    name: '环形饼图',
                    type: 'pie',
                    radius: ['68%', '80%'],
                    hoverAnimation: false,
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [{
                        value: this.selfMajorNum,
                        name: '',
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#6984fe'
                                },{
                                    offset: 1,
                                    color: '#24d1fd'
                                }])
                            }
                        }
                    },{
                        value: this.majorNum - this.selfMajorNum,
                        name: '',
                        itemStyle: {
                            normal: {
                                color: '#eee'
                            }
                        }
                    }]
                }]
            }

            myChart.setOption(this.option, true);

            window.addEventListener('resize', () => {
                myChart.resize();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .business-container {
        margin-top: 0.75rem;
        .chart {
            height: 2.5rem;
            margin-left: -3rem;
        }
        .right-chart {
            height: 2.5rem;
            margin-top: -2.72rem;
            margin-left: 3rem;
        }
        .text{
            font-size: 0.2rem;
            color: #b3ccf8;
            width: 50px;
            margin-left: 0.3rem;
        }
        .right-text{
            font-size:  0.2rem;
            color: #b3ccf8;
            margin-left: 3.5rem;
        }
    }
</style>