<template>
    <div class="business-container">
        <div class="chart" id="chart_left1"></div>
    </div>
</template>

<script>
    import api from '@/api'

    export default {
        name: 'major-top',
        data() {
            return {

            }
        },
        mounted() {
            this.getEchartLeft1();
        },
        methods: {
            async getEchartLeft1() {
                // 实例化对象
                let myChart = echarts.init(document.getElementById('chart_left1'));
                /*let charts = {
                    xData: ["行政管理", "工商企业管理", "学前教育", "药学", "机电一体化技术"],
                    yData: [ 12118,4720, 3619, 2448, 1649]

                }*/
                let xValue = [];
                let yValue = [];
                let res = await api.bigdata.getMajorStudTopList({organizationId: localStorage.getItem('organizationId')})
                if (res.code === 200) {
                    xValue = res.data.map( o => { return [o.majorName].toString()});
                    yValue = res.data.map( o => { return Number([o.num].toString())});
                }
                let color = ['rgba(14,109,236', 'rgba(255,91,6', 'rgba(100,255,249', 'rgba(248,195,248', 'rgba(110,234,19'];

                let lineY = [];
                for (let i = 0; i < xValue.length; i++) {
                    let data = {
                        name: xValue[i],
                        color: color[i] + ')',
                        value: yValue[i],
                        itemStyle: {
                            normal: {
                                show: true,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: color[i] + ', 0.3)'
                                }, {
                                    offset: 1,
                                    color: color[i] + ', 1)'
                                }], false),
                                barBorderRadius: 10
                            },
                            emphasis: {
                                shadowBlur: 15,
                                shadowColor: 'rgba(0, 0, 0, 0.1)'
                            }
                        }
                    }
                    lineY.push(data)
                }
                console.log(lineY)
                // 指定配置和数据
                let option = {
                    color: color,
                    // ---  提示框 ----
                    tooltip: {
                        trigger: 'item',// 数据项图形触发
                    },
                    // ---- gird区域 ---
                    grid: {
                        borderWidth: 0,
                        top: '5%',
                        left: '2%',
                        right: '2%',
                        bottom: '0%',
                        containLabel: true
                    },
                    //  ------  X轴 ------
                    xAxis: [{
                        type: 'category', // 轴类型， 默认为 'category'
                        axisTick: { // 坐标轴 刻度
                            show: true  // 是否显示
                        },
                        axisLine: { // 坐标轴 轴线
                            show: true // 是否显示
                        },
                        axisLabel: { // 坐标轴标签
                            show: true, // 是否显示
                            interval:0,//代表显示所有x轴标签显示
                            inside: false,  // 是否朝内
                            textStyle: { // 样式
                                color: '#b3ccf8',
                                fontSize: 13
                            },
                        },
                        data: xValue //数据
                    }],
                    //   ------   y轴  ----------
                    yAxis: [{
                        type: 'value', // 轴类型
                        axisTick: {
                            show: true
                        },
                        axisLine: {
                            show: true
                        },
                        splitLine: { // gird区域中的分割线
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            textStyle: { // 样式
                                color: '#b3ccf8',
                                fontSize: 13
                            },
                        }
                    }],
                    //  -------   内容数据 -------
                    series: [{
                        name: '',
                        type: 'bar',  // 图类型  柱状图
                        zlevel: 0,
                        barWidth: '10px', // 柱形的宽度
                        data: lineY,
                        animationDuration: 1500,
                        label: { // 图形上的文本标签
                            normal: {
                                color: '#b3ccf8',
                                show: false,
                                position: [0, '-15px'],
                                textStyle: {
                                    fontSize: 13
                                },
                                formatter: (a, b) => {
                                    return a.name;
                                }
                            }
                        }
                    }]
                };
                // 把配置给实例对象
                myChart.setOption(option, true);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .business-container {
        .chart {
            height: 3rem;
        }
    }
</style>